<template>
  <section class="page">
    <SearchFilter
      class="mb-16"
      :loading="loading"
      :form="searchForm"
      :principal-list="principalList"
      @search="handleSearch"
    />
    <DataTable
      :loading="loading"
      :data="data"
      :pagination="pagination"
      @pagination-change="handlePaginationChange"    
    />
  </section>
</template>

<script>
import SearchFilter from './components/SearchFilter';
import DataTable from './components/DataTable';
import auditApi from "@/api/auditManage";
import trainingApi from "@/api/training_list";

export default {
  name: "auditConfigLog",
  components: {
    SearchFilter,
    DataTable
  },
  data() {
    return {
      loading: false,
      searchForm: {
        mtime: [],
        modifier: "",
        principal_id: undefined
      },
      principalList: [],
      data: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0
      }
    };
  },
  created() {
    this.fetchPrincipalList();
    this.fetchAuditConfigLogList();
  },
  methods: {
    // 获取主体列表
    async fetchPrincipalList() {
      try {
        const { code, data, message } = await trainingApi.getPrincipals();

        if (code === 200) {
          this.principalList = data;
        } else {
          this.$message.error(message);
        }
      } catch(e) {
        console.log(e);
      }
    },
    // 获取免审配置操作日志列表
    async fetchAuditConfigLogList() {
      this.loading = true;
      try {
        const { mtime, ...restSearchForm } = this.searchForm
        const { page, size } = this.pagination
        const params = {
          ...restSearchForm,
          mtime_from: mtime.length > 0 ? `${mtime[0]} 00:00:00` : "",
          mtime_to: mtime.length > 0 ? `${mtime[1]} 23:59:59` : "",
          page_num: page,
          page_size: size
        }
        const { code, data, message } = await auditApi.fetchAuditConfigLogList(params);

        if (code === 200) {
          this.data = data.list;
          this.pagination.total = data.total;
        } else {
          this.$message.error(message);
        }

        this.loading = false;
      } catch(e) {
        console.log(e);
        this.loading = false;
      }
    },
    handleSearch() {
      this.pagination.page = 1;
      this.fetchAuditConfigLogList();
    },
    handlePaginationChange(page, size) {
      this.pagination.page = page;
      this.pagination.size = size;
      this.fetchAuditConfigLogList();
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-16 {
  margin-bottom: 16px;
}

.page {
  padding: 0 !important;
  background: #f0f2f5 !important;
}
</style>