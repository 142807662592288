<template>
  <div class="data-table">
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :row-key="(record, index) => index"
      :pagination="false"
    >
      <div class="avatar" slot="avatar" slot-scope="text, record">
        <a-avatar
          icon="user"
          shape="square"
          :size="64"
          :src="`${ossHost3}/${record.avatar}`"
        />
      </div>
      <div slot="status" slot-scope="text, record">
        <a-tag color="red" v-if="record.status === 1">取消免审</a-tag> 
        <a-tag color="green" v-else>设置免审</a-tag>
      </div>  
    </a-table>

    <base-pagination
      :currentPage="pagination.page"
      :pageSize="pagination.size"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "Logo",
    key: "avatar",
    scopedSlots: { customRender: 'avatar' },
  },
  {
    title: "主体名称",
    dataIndex: "principal_name"
  },
  {
    title: "主体ID",
    dataIndex: "principal_id"
  },
  {
    title: "操作时间",
    dataIndex: "mtime"
  },
  {
    title: "操作",
    key: "status",
    scopedSlots: { customRender: 'status' }
  },
  {
    title: "操作人",
    dataIndex: "modifier"
  }
];
const ossHost3 = process.env.VUE_APP_OSS_HOST3;

export default {
  name: "DataTable",
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    pagination: {
      type: Object,
      default() {
        return {
          page: 1,
          size: 10,
          total: 0
        }
      }
    }
  },
  data() {
    return {
      columns,
      ossHost3
    };
  },
  methods: {
    handlePaginationChange(page, size) {
      this.$emit('pagination-change', page, size)
    }
  }
}
</script>

<style lang="scss" scoped>
.data-table {
  padding: 24px;
  background: #fff;
}
</style>
