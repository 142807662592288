<template>
  <div class="search-filter">
    <a-form-model
      class="form"
      ref="form"
      :model="form"
      :label-col="{span: labelColSpan}"
      :wrapper-col="{span: wrapperColSpan}"
    >
      <a-row :gutter="16">
        <a-col :span="colSpan">
          <a-form-model-item label="操作时间" prop="mtime">
            <a-range-picker
              v-model="form.mtime"
              value-format="YYYY-MM-DD"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="操作人" prop="modifier">
            <a-input
              v-model="form.modifier"
              placeholder="请输入"
              allow-clear
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan">
          <a-form-model-item label="主体" prop="principal_id">
            <a-select
              v-model="form.principal_id"
              placeholder="请选择"
              allow-clear
              :default-active-first-option="false"
              show-search
              option-filter-prop="children"
              style="width: 100%;"
            >
              <a-select-option
                v-for="item in principalList"
                :key="item.id"
                :value="item.id"
              >
                {{ item.principal_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="colSpan2">
          <a-form-model-item  :wrapper-col="{span: 24}">
            <div class="btns">
              <a-button class="mr-8" @click="handleReset">
                重置
              </a-button>
              <a-button type="primary" :loading="loading" @click="handleSearch">
                查询
              </a-button>
            </div>  
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import resizeMixin from "@/mixins/dataTableMixin";

export default {
  name: "SearchFilter",
  mixins: [resizeMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object,
      default() {
        return {};
      }
    },
    principalList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    this.$_resizeHandler = () => {
      this.setColSpan();
    };

    return {
      labelColSpan: 5,
      wrapperColSpan: 19,
      colSpan: 12,
      colSpan2: 12
    };
  },
  created() {
    this.setColSpan();
  },
  methods: {
    setColSpan() {
      const width = document.documentElement.offsetWidth;

      if (width < 1200) {
        this.colSpan = 12;
        this.colSpan2 = 12;
      } else if (width >= 1200 && width < 1600) {
        this.colSpan = 8;
        this.colSpan2 = 24;
      } else if (width >= 1600) {
        this.colSpan = 6;
        this.colSpan2 = 6;
      }
    },
    handleReset() {
      this.$refs.form.resetFields();
      this.$emit('search', {...this.form});
    },
    handleSearch() {
      this.$emit('search', {...this.form});
    }
  }
}
</script>

<style lang="scss" scoped>
.mr-8 {
  margin-right: 8px;
}

.search-filter {
  padding: 24px 24px 8px;
  background: #fff;
}

.ant-form-item {
  margin-bottom: 16px;
}

.btns {
  text-align: right;
}
</style>
